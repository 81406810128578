//
// authentication.scss
//

.auth-logo {
  .logo {
    margin: 0px auto;
  }

  .logo-dark {
    display: $display-block;
  }

  .logo-light {
    display: $display-none;
  }
}

.auth-bg {
  background-color: rgba(var(--bs-primary-rgb), 1);
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}

.auth-logo-section {
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    height: 100vh;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
}

.authentication-page-content {
  height: calc(100% - 48px);
  background-color: $white;
  border-radius: 16px;
  margin: 24px;
}

.auth-img {
  position: relative;
  @media (min-width: 992px) and (max-width: 1549.98px) {
    max-width: 220%;
  }
  @media (min-width: 1550px) {
    max-width: 200%;
  }
  @media (max-width: 991.98px) {
    display: none;
  }
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="text"] + .btn .ri-eye-fill {
    &:before {
      content: "\ec80";
    }
  }
}

// signin card title

.signin-other-title {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: $border-color;
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: $card-bg;
    padding: 2px 16px;
  }
}
.crafted-by-text {
  @media (max-width: 991.98px) {
    display: none;
  }
}

body[data-layout-mode="dark"] {
  .authentication-page-content {
    background-color: $gray-dark-200;
  }

  .signin-other-title {
    .title {
      background-color: $gray-dark-200;
    }

    &::after {
      background-color: $gray-dark-300;
    }
  }
}


